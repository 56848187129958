import { Component, Emit, Inject, Vue } from 'vue-property-decorator';
import { GoAppsComponent as GoApps, GoLanguageComponent as GoLanguage } from '@gv/ammo-grome';
import GoAccount from '@/core/raptor/go-account/go-account.vue';
import TranslationService from '@/locale/translation.service';
import LoginService from '@/account/login.service';

/*
 * @author ricardo.gaspar@grupo-valco.com
 */
@Component({
  components: {
    'go-account': GoAccount,
    'go-apps': GoApps,
    'go-language': GoLanguage,
  },
})
export default class GoAppBar extends Vue {
  @Inject('loginService')
  private loginService!: () => LoginService;

  @Inject('translationService')
  private translationService!: () => TranslationService;

  private currentLanguage = this.$store.getters.currentLanguage;

  // eslint-disable-next-line
  private languages: { [k: string] : any } = this.$store.getters.languages;

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
    this.setDocumentTitle();
  }

  @Emit('drawerClick')
  // eslint-disable-next-line
  drawerBubbleUp() {}

  @Emit('drawerRightClick')
  // eslint-disable-next-line
  drawerRightBubbleUp() {
  }

  public get authenticated(): boolean {
    // return this.$store.getters.authenticated
    return this.$store.getters['workCenterStore/isLoggedIn'];
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
    this.setDocumentTitle();
    if (this.$vuetify) {
      this.$vuetify.lang.current = newLanguage.substring(0, 2);
    }
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public openLogin() {
    const next = '/auth';
    return this.$router.push(next);
  }

  public logout() {
    this.loginService().logout();
    this.$router.push({ name: 'EmployeeAuthentication' });
  }

  private setDocumentTitle(): void {
    document.title = `${this.$t('global.title')}`;
  }
}
