import messageHandlerSupport from '@/shared/raptor/messaging/support/message/message-handler-support';
// import store from "@/store"
// import { notifier } from "@/mixins/notifier"
import { yakovlevStoreMutationTypes, operatorLandingStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import { MessageType } from '@/shared/raptor/messaging/support/message/message-type';
// import { DeliveryStatus } from '@/shared/raptor/messaging/support/message/message-flow';
import { MessageStatus } from '@/shared/raptor/messaging/support/message/message-status';
import { ResponseStatusError } from '@/shared/raptor/messaging/support/error/response-status-error';
import ApplicationContext from '@/shared/raptor/context/application-context';
import * as AlertUtils from '@/shared/raptor/util/alert-utils';
import { IOperatorLandingContext } from '@/shared/model/raptor/messaging/support/operator-landing-context.model';
import { IPublishPayload } from '@/shared/model/raptor/messaging/support/publish-payload.model';
import * as alertUtils from '@/shared/raptor/util/alert-utils';
import { appLogLevel, LogLevel } from '@/shared/config/logger-spe';
import { FromOperatorLandingLine as customOperationTrackFromOperatorLandingLine } from '@/shared/model/raptor/operation-track-qualifying-fields.model';
import { CompletePayload } from '@/shared/model/raptor/operator-landing.model';
import { IDoorInspection } from '@/shared/model/raptor/door-inspection.model';
import { ICustomOperationTrack } from '@/shared/model/custom-operation-track.model';
import { AxiosResponse } from 'axios';
import { IShopFloorConveyor } from '@/shared/model/shop-floor-conveyor.model';

const logLevel = appLogLevel();

/**
 * Publish action
 * @param device
 * @param stage
 * @param step
 * @param type request or response
 * @param payload
 */
function publish(device, stage, step, type, payload: IPublishPayload): Promise<any> {
  logLevel === LogLevel.DEBUG &&
    console.debug(`Request to publish : device=${device.code}, stage=${stage.name}, step=${step.name}, type=${type}, payload=`, payload);
  return new Promise<any>((resolve, reject) => {
    let message: { [k: string]: any };
    try {
      message = messageHandlerSupport.build(device, stage, step, type, payload);
    } catch (err) {
      reject(err);
    }

    return ApplicationContext.getStore()
      .dispatch(yakovlevStoreMutationTypes.publishTopic, message)
      .then(res => {
        logLevel === LogLevel.DEBUG && console.debug(`Request to complete succeeded`, res);
        resolve(res);
      })
      .catch(err => {
        console.error(`Request to publishing failed : ${err.message}`, err);
        reject(err);
      });
  });
}

/**
 * Complete action
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function complete(device, stage, step, payload: IOperatorLandingContext): Promise<any> {
  logLevel === LogLevel.DEBUG &&
    console.debug(`Request to complete : device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`, payload);
  return new Promise<any>((resolve, reject) => {
    const completePayload = new CompletePayload(
      customOperationTrackFromOperatorLandingLine({ ...payload.getInnerPayload() }, 1),
      payload.data
    );
    ApplicationContext.getStore()
      .dispatch(operatorLandingStoreMutationTypes.complete, completePayload)
      .then(res => {
        logLevel === LogLevel.DEBUG && console.debug(`Request to complete succeeded`, res);
        resolve(res);
      })
      .catch(err => {
        console.error(`Request to complete failed : ${err.message}`, err);
        AlertUtils.showError(err);
        reject(err);
      });
  });
}

/**
 * Complete without operation track, e.g., the operation track was performed externally
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function completeWithoutOperationTrack(device, stage, step, payload: IOperatorLandingContext): Promise<any> {
  logLevel === LogLevel.DEBUG &&
    console.debug(
      `Request to completeWithoutOperationTrack: device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`,
      payload
    );
  return new Promise<any>((resolve, reject) => {
    const completePayload = new CompletePayload(payload.getInnerPayload(), payload.data);
    return ApplicationContext.getStore()
      .dispatch(operatorLandingStoreMutationTypes.completeWithoutOperationTrack, completePayload)
      .then(res => {
        logLevel === LogLevel.DEBUG && console.debug(`Request to completeWithoutOperationTrack succeeded`, res);
        resolve(res);
      })
      .catch(err => {
        console.error(`Request to completeWithoutOperationTrack failed : ${err.message}`, err);
        AlertUtils.showError(err);
        reject(err);
      });
  });
}

/**
 * Complete with operation track
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function operationTrack(device, stage, step, payload): Promise<any> {
  logLevel === LogLevel.DEBUG &&
    console.debug(
      `Request to save CustomOperationTrack : device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`,
      payload
    );
  return new Promise<any>((resolve, reject) => {
    const completePayload = new CompletePayload(customOperationTrackFromOperatorLandingLine({ ...payload.data.rowData }, 1), payload.data);

    ApplicationContext.getStore()
      .dispatch(operatorLandingStoreMutationTypes.complete, completePayload)
      .then(res => {
        logLevel === LogLevel.DEBUG && console.debug(`Request to save CustomOperationTrack succeeded`, res);
        resolve(res);
      })
      .catch(err => {
        console.error(`Request to save CustomOperationTrack failed : ${err.message}`, err);
        AlertUtils.showError(err);
        reject(err);
      });
  });
}

/**
 * Print label
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function printLabel(device, stage, step, payload): Promise<any> {
  logLevel === LogLevel.DEBUG &&
    console.debug(`Request to print label : device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`, payload);
  return new Promise<any>((resolve, reject) => {
    ApplicationContext.getStore()
      .dispatch(operatorLandingStoreMutationTypes.printLabel, { ...payload.getInnerPayload(), data: payload.data })
      .then(res => {
        logLevel === LogLevel.DEBUG && console.debug(`Request to print label succeeded`, res);
        resolve(res);
      })
      .catch(err => {
        console.error(`Request to print label failed : ${err.message}`, err);
        AlertUtils.showError(err);
        reject(err);
      });
  });
}

/**
 * Perform door inspect
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function doorInspect(device, stage, step, payload): Promise<any> {
  logLevel === LogLevel.DEBUG &&
    console.debug(`Request to door inspect : device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`, payload);
  return new Promise<any>((resolve, reject) => {
    ApplicationContext.getStore()
      .dispatch(operatorLandingStoreMutationTypes.doorInspect, { ...payload.getInnerPayload(), data: payload.data })
      .then((res: IDoorInspection) => {
        logLevel === LogLevel.DEBUG && console.debug(`Request to door inspect succeeded`, res);

        const completePayload = new CompletePayload(
          res.customOperationTrack,
          ApplicationContext.getStore().getters['operatorLandingStore/byOrderNumberAndOperationAndOperationSplit']({
            ...res.customOperationTrack,
          })
        );
        ApplicationContext.getStore()
          .dispatch(operatorLandingStoreMutationTypes.complete, completePayload)
          .then((completeResponse: AxiosResponse<ICustomOperationTrack>) => {
            logLevel === LogLevel.DEBUG && console.debug(`Request to complete door inspect succeeded`, res);
            // Update request with response status
            ApplicationContext.getStore()
              .dispatch(yakovlevStoreMutationTypes.updateMessageStatus, {
                uuid: payload.getInnerHeaders().uuid,
                response: {
                  status: completeResponse.status,
                  message: completeResponse.statusText,
                },
              })
              .then(() => {
                const axiosResponse: AxiosResponse<IDoorInspection> = { ...completeResponse, data: { ...res } };
                publish(device, stage, step, MessageType.RESPONSE, messageHandlerSupport.prepareResponse(payload, axiosResponse));
              });
          });
      })
      .catch(err => {
        console.error(`Request to door inspect failed : ${err.message}`, err);
        AlertUtils.showError(err);
        reject(err);
      });
  });
}

/**
 * Perform conveyor arrival part
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function conveyorArrivalPart(device, stage, step, payload): Promise<any> {
  logLevel === LogLevel.DEBUG &&
    console.debug(
      `Request to update conveyorArrivalPart : device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`,
      payload
    );

  return new Promise<any>((resolve, reject) => {
    const conveyor: IShopFloorConveyor = payload.deserializedPayload.payload;

    ApplicationContext.getStore()
      .dispatch(operatorLandingStoreMutationTypes.updateConveyor, conveyor)
      .then(res => {
        logLevel === LogLevel.DEBUG && console.debug(`Request to update conveyorArrivalPart succeeded`, res);
        resolve(res);
      })
      .catch(err => {
        console.error(`Request to update conveyorArrivalPart failed : ${err.message}`, err);
        reject(err);
      });
  });
}

function linkDocumentToWorkOrder(device, stage, step, payload: IOperatorLandingContext) {
  logLevel === LogLevel.DEBUG &&
    console.debug(
      `Request to save LinkDocumentToWorkOrder : device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`,
      payload
    );
  // Redefine completed quantity to one, automation is sending "Amount of total completed quantity" instead of
  // "total completed quantity"
  const redefinedPayload = { ...payload.getInnerPayload(), data: payload.data };
  //@ts-ignore
  redefinedPayload.totalCompletedQty = 1;

  ApplicationContext.getStore()
    .dispatch(operatorLandingStoreMutationTypes.linkDocumentToWorkOrder, redefinedPayload)
    .then(res => {
      // Update request with response status
      ApplicationContext.getStore()
        .dispatch(yakovlevStoreMutationTypes.updateMessageStatus, {
          uuid: payload.getInnerHeaders().uuid,
          response: {
            status: res.status,
            message: res.statusText,
          },
        })
        .then();
      return res;
    })
    .then(res => {
      logLevel === LogLevel.DEBUG && console.debug(`Request to save LinkDocumentToWorkOrder succeeded`, res);
      publish(device, stage, step, MessageType.RESPONSE, messageHandlerSupport.prepareResponse(payload, res)).then();
      complete(device, stage, step, payload).then();
    })
    .catch(err => {
      console.error(`Request to save LinkDocumentToWorkOrder failed : ${err.message}`, err);
      publish(device, stage, step, MessageType.RESPONSE, messageHandlerSupport.prepareResponse(payload, err)).then();
      // Update request with response status
      ApplicationContext.getStore()
        .dispatch(yakovlevStoreMutationTypes.updateMessageStatus, {
          uuid: payload.getInnerHeaders().uuid,
          response: {
            status: err.response.status,
            message: err.response.message,
          },
        })
        .then();
    });
}

/**
 * Link document to work order and then track
 */
function linkDocumentToWorkOrderThenTrack(device, stage, step, payload: IOperatorLandingContext): Promise<any> {
  logLevel === LogLevel.DEBUG &&
    console.debug(
      `Request to save linkDocumentToWorkOrderThenTrack : device=${device.code}, stage=${stage.name}, step=${step.name}, payload=`,
      payload
    );

  // Redefine completed quantity to one, automation is sending "Amount of total completed quantity" instead of
  // "total completed quantity"
  const redefinedPayload = { ...payload.getInnerPayload(), data: payload.data };
  // @ts-ignore
  redefinedPayload.totalCompletedQty = 1;

  const i18n = ApplicationContext.getI18n();

  return new Promise<any>((resolve, reject) => {
    ApplicationContext.getStore()
      .dispatch(operatorLandingStoreMutationTypes.linkDocumentToWorkOrderThenTrack, redefinedPayload)
      .then(res => {
        logLevel === LogLevel.DEBUG && console.debug(`Request to save linkDocumentToWorkOrderThenTrack succeeded`, res);
        alertUtils.showSuccess(
          `${i18n.t('raptor.operatorLanding.successfullyLinkDocumentToWorkOrderThenTrack', {
            '0': res.data.customOperationTrack.id,
            '1': res.data.customOperationTrack.identifier1,
            '2': res.data.customOperationTrack.orderNumber,
          })}`
        );
        // Update request with response status
        return ApplicationContext.getStore()
          .dispatch(yakovlevStoreMutationTypes.updateMessageStatus, {
            uuid: payload.getInnerHeaders().uuid,
            response: {
              status: res.status,
              message: res.statusText,
            },
          })
          .then(() => res);
      })
      .then(res => {
        return publish(device, stage, step, MessageType.RESPONSE, messageHandlerSupport.prepareResponse(payload, res));
      })
      .catch(err => {
        console.error(`Request to save linkDocumentToWorkOrderThenTrack failed : ${err.message}`, err);
        alertUtils.showError(err, null);
        return publish(device, stage, step, MessageType.RESPONSE, messageHandlerSupport.prepareResponse(payload, err))
          .then(() => {})
          .catch(publishErr => {
            AlertUtils.showError(
              `${i18n.t('raptor.operatorLanding.errorCouldNotPublishMessage', {
                '0': publishErr.message,
              })}`,
              null
            );
          })
          .finally(() => {
            // Update request with response status
            ApplicationContext.getStore()
              .dispatch(yakovlevStoreMutationTypes.updateMessageStatus, {
                uuid: payload.getInnerHeaders().uuid,
                response: {
                  status: err.response.status,
                  message: err.response.message,
                },
              })
              .then(() => {})
              .catch(publishErr => {
                AlertUtils.showError(
                  `${i18n.t('raptor.operatorLanding.errorCouldNotUpdateMessageState', {
                    '0': publishErr.message,
                  })}`,
                  null
                );
              });
          });
      });
  });
}

function hydrateStatusAndMessage(error) {
  let status = MessageStatus.BAD_REQUEST.value;
  let message = MessageStatus.BAD_REQUEST.reasonPhrase;

  const response = error.hasOwnProperty('response') ? error.response : null;

  if (error instanceof ResponseStatusError) {
    status = response.status;
    message: response.data.message !== '' ? response.data.message : response.statusText;
  } else if (error instanceof Error) {
    // AXIOS error
    if (error.hasOwnProperty('response')) {
      if (response.hasOwnProperty('status')) {
        status = response.status;
      }

      if (response.hasOwnProperty('statusText')) {
        message = response.statusText;
      }
      if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message') && response.data.message !== '') {
        message = response.data.message;
      }
    } else {
      message = error.message;
    }
  }
  return { status, message };
}

export default {
  publish,
  complete,
  printLabel,
  doorInspect,
  conveyorArrivalPart,
  operationTrack,
  linkDocumentToWorkOrder,
  linkDocumentToWorkOrderThenTrack,
  completeWithoutOperationTrack,
};
