import kioskComponent from '@/core/spe/kiosk/kiosk.vue';

const WidgetSidebar = () => import('@/core/spe/kiosk/widget-sidebar/widget-sidebar.vue');
const CalendarEventView = () => import('@/core/spe/kiosk/widget-sidebar/go-components/go-gadget-calendar/calendar-event-view.vue');

export default [
  {
    path: '/kiosk',
    name: 'kiosk',
    component: kioskComponent,
    meta: {},
  },
  {
    path: '/kiosk/widget-sidebar',
    name: 'WidgetSidebar',
    component: WidgetSidebar,
    meta: {},
  },
  {
    path: '/spe/calendar/events/view',
    name: 'CalendarEventView',
    component: CalendarEventView,
    meta: {},
  },
  {
    path: '/spe/calendar/:calendarId/events/view',
    name: 'CalendarEventView2',
    component: CalendarEventView,
    meta: {},
  },
];
