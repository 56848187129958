/**
 * Formely auth.js
 */
import {
  workCenterStoreMutationTypes,
  workCenterDeviceStoreMutationTypes,
  workCenterOperatorStoreMutationTypes,
} from '@/shared/config/store/raptor/mutation-types/types';
import { AxiosRequestConfig, AxiosStatic } from 'axios';
import * as Qs from 'qs';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import { IRaptorAuth } from '@/shared/model/raptor/raptor-auth.model';

const baseApiUrl = 'api/v1';

const axiosConfig: AxiosRequestConfig = {
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
};

export default class LoginService {
  private readonly _axios: AxiosStatic;

  constructor(axios: AxiosStatic, private store: Store<any>, private cookie: any, private router: VueRouter) {
    this._axios = axios;
  }

  public get axios(): AxiosStatic {
    return this._axios;
  }

  public login(payload: { [k: string]: any }): Promise<any> {
    return new Promise((resolve, reject) => {
      // Commit auth request
      this.store.commit(workCenterStoreMutationTypes.authRequest);

      const token = payload.token;
      const deviceId = payload.deviceId;
      const remember = payload.remember;
      const folderId = payload.folderId;

      this._axios
        .post(`${baseApiUrl}/login`, { token, deviceId }, axiosConfig)
        .then(response => {
          if (response.data.success) {
            // let session = VueEasySession.getInstance();
            // session.start();
            // session.set('token', response.data.accessToken.token);
            this.cookie.set('token', response.data.accessToken.token);

            this.cookie.set('deviceId', response.data.accessToken.deviceId, { expires: '1Y' });

            if (remember) this.cookie.set('token', response.data.accessToken.token, '1Y');

            // build user object
            const accessToken = response.data.accessToken;
            const user = {
              name: `${accessToken.user.username}  ${accessToken.user.site}`,
              email: accessToken.user.email || '',
              data: { ...accessToken.user }, // Wrap additional data in a data property
            };

            // Commit auth success
            this.store.commit(workCenterStoreMutationTypes.authSuccess, { token: accessToken.token, user });
          }
          return response;
        })
        .then(response => {
          return this.retrieveAdditionalData();
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          this.store.commit(workCenterStoreMutationTypes.authError);
          reject(error);
        });
    });
  }

  public introspect(token: string): Promise<IRaptorAuth> {
    return new Promise((resolve, reject) => {
      this._axios
        .get(`${baseApiUrl}/token-introspect/${token}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public logout(): void {
    // let session = VueEasySession.getInstance()
    // session.destroy()

    const deviceId = this.cookie.get('deviceId');
    this.cookie.delete('token');

    if (deviceId != null) {
      this._axios.post(`${baseApiUrl}/delete-token-by-device-id/${deviceId}`).then(() => {
        this.cookie.delete('deviceId');
      });
    }

    this.router.push('/').catch(error => {
      console.log(error);
    });

    this.store.commit(workCenterStoreMutationTypes.logout);
  }

  public retrieveAdditionalData() {
    // Return a promise that resolves when all the dispatches complete
    return new Promise((resolve, reject) => {
      Promise.all([
        // Dispatch for work center data
        this.store.dispatch('workCenterStore/retrieveCorrelatedData'),

        // Dispatch for work center devices
        this.store.dispatch(workCenterDeviceStoreMutationTypes.fetchData, {
          workCenter: this.store.getters['workCenterStore/user'].data.username,
          manufacturingSite: this.store.getters['workCenterStore/user'].data.site,
        }),

        // Dispatch for work center operators
        this.store.dispatch(workCenterOperatorStoreMutationTypes.fetchData, {
          workCenter: this.store.getters['workCenterStore/user'].data.username,
          manufacturingSite: this.store.getters['workCenterStore/user'].data.site,
        }),
      ])
        .then(() => {
          // Resolve the promise once all the actions complete successfully
          resolve('All data fetched successfully!');
        })
        .catch(error => {
          // Reject the promise if any of the actions fail
          reject(error);
        });
    });
  }
}
