var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.widgetData
    ? _c("v-skeleton-loader", { attrs: { type: "image" } })
    : _c(
        "v-card",
        { attrs: { elevation: "2", outlined: "", rounded: "" } },
        [
          _c("v-card-title", [
            _c("h4", [_vm._v(_vm._s(_vm.currentDayOfWeek))]),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-list-item-content", [
                        _c("div", { staticClass: "current-date" }, [
                          _vm._v(_vm._s(_vm.currentDay)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    _vm._l(_vm.filterEventsByDay, function (event) {
                      return _c(
                        "v-list-item",
                        { key: event.id, attrs: { dense: "" } },
                        [
                          _c("div", {
                            staticClass: "event-bar",
                            style: {
                              backgroundColor: _vm.getEventColor(event),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(event.name)),
                              ]),
                              _vm._v(" "),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatTime(event.start)) +
                                    " - " +
                                    _vm._s(_vm.formatTime(event.end)) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }