var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            {
              style: {
                minWidth: `calc(100% - ${
                  _vm.$vuetify.breakpoint.lgAndUp ? 323 : 0
                }px)`,
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _vm._l(_vm.items, function (item, i) {
                        return [
                          _c(
                            "v-col",
                            {
                              key: i,
                              attrs: { cols: "10", lg: "4", md: "4", sm: "6" },
                            },
                            [
                              _c(
                                "v-hover",
                                [
                                  _c("v-card", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.url,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-img",
                                          {
                                            attrs: {
                                              src: item.img,
                                              height: "175px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "ma-0 text-h4 white--text font-weight-bold text-center fill-height align-content-center",
                                                staticStyle: {
                                                  "word-break": "break-word",
                                                  "overflow-wrap": "break-word",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: {
                                                      align: "center",
                                                      justify: "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mt-4 subheading text-left ma-0 text-h4 white--text font-weight-bold text-center fill-height align-content-center",
                                                        staticStyle: {
                                                          "-webkit-text-stroke-width":
                                                            "0.05rem",
                                                          "-webkit-text-stroke-color":
                                                            "black",
                                                          "word-break":
                                                            "break-word",
                                                          "overflow-wrap":
                                                            "break-word",
                                                        },
                                                        style: `color:${item.color}`,
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(item.title)
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.lgAndUp
            ? _c(
                "v-col",
                { style: { width: "323px" }, attrs: { cols: "auto" } },
                [_c("div", [_c("widget-sidebar")], 1)]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }