import axios from 'axios';

const baseApiUrl = 'services/panther/api/v1/calendar-events';

export default class CalendarEventExtendedService {
  public retrieve(criteria?: { [k: string]: any }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params: { ...criteria } })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
