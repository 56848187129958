import { Component, Provide, Vue } from 'vue-property-decorator';

import dayjs from 'dayjs';
import TimeUtils from '../utils/time-utils';
import { formatTime } from '../utils/calendar-utils';
import { nullIfEmpty } from '@/shared/spe/filters';

import { IWidgetGallery } from '@/shared/model/spe/kiosk/widget-sidebar/widget-gallery/widget-gallery.model';

import SocietyExtendedService from '@/entities/spe/kiosk/widget-sidebar/society/society-extended.service';
import CalendarEventExtendedService from '@/entities/spe/kiosk/widget-sidebar/calendar-event/calendar-event-extended.service';

@Component({
  methods: { formatTime },
})
export default class GoGadgetCalendarEventList extends Vue {
  @Provide('calendarEventExtendedService')
  private calendarEventExtendedService = () => new CalendarEventExtendedService();

  @Provide('societyExtendedService')
  private societyExtendedService = () => new SocietyExtendedService();

  public widgetGallery: IWidgetGallery = {};
  public filter: { [k: string]: any } = {};

  public calendarEvents = [];
  public filteredEvents: string[] = [];

  public currentDay: string = TimeUtils.getCurrentDay();

  public dialog = false;
  public isFetching = false;

  created() {
    this.widgetGallery = { ...this.$attrs };
  }

  async mounted() {
    this.retrieveAllCalendarEvents();
    await this.retrieveFilter();
    await this.retrieveCalendarEventsByFilter();
  }

  public get widgetData() {
    return this?.widgetGallery?.data ?? null;
  }

  public get currentDayOfWeek(): string {
    return TimeUtils.getCurrentDayOfWeek(this.$t.bind(this));
  }

  /**
   * Retrieve all Events
   */
  public retrieveAllCalendarEvents() {
    this.isFetching = true;
    this.calendarEventExtendedService()
      .retrieve()
      .then(res => {
        this.calendarEvents = res.data.filter(it => it.calendarId);
        this.isFetching = false;
      });
  }

  public async retrieveFilter(): Promise<void> {
    if (this.widgetGallery.interCompany) {
      try {
        this.isFetching = true;
        const res = await this.societyExtendedService()
          //@ts-ignore
          .findByCodeInSocietyGroup(this.widgetGallery.societyId);
        this.filter['calendar.society_id.in'] = nullIfEmpty(res.map(it => it.id));
      } catch (error) {
        console.error('Error fetching societies by group ID:', error);
      } finally {
        this.isFetching = false;
      }
    }
  }

  public async retrieveCalendarEventsByFilter(): Promise<void> {
    if (!this.filter['calendar.society_id.in']) {
      console.warn('Filter not set.');
      return;
    }

    try {
      this.isFetching = true;
      // Fetch all calendar events

      const res = await this.calendarEventExtendedService().retrieve();
      this.calendarEvents = res.data;

      // Convert filter string to array of integers
      const calendarIds = this.filter['calendar.society_id.in'].split(',').map((id: string) => parseInt(id, 10));

      this.filteredEvents = this.calendarEvents.filter(event => calendarIds.includes(event.calendar.id)).map(event => ({ ...event }));
    } catch (error) {
      console.error('Error retrieving calendar events:', error);
    } finally {
      this.isFetching = false;
    }
  }

  public get filterEventsByDay(): any[] {
    const today = dayjs().startOf('day');

    return this.filteredEvents.filter(event => {
      //@ts-ignore
      const eventStart = dayjs(event.start);
      //@ts-ignore
      const eventEnd = dayjs(event.end);

      const eventDuration = eventEnd.diff(eventStart, 'day', false); //set to true if event duration is fractional (e.g: 1.5 days)

      //@ts-ignore
      return eventStart.isSame(today, 'day') && eventDuration <= this.widgetGallery.data.untilAt; // Make sure the event duration doesn't exceed untilAt;
    });
  }

  public getEventColor(event): string {
    switch (event.calendarId) {
      case 1:
        return '#BDBDBD'; //grey-lighten-1
      case 2:
        return '#757575'; //grey-darken-2
      case 3:
        return '#000000'; // black
    }
  }
}
