import { Component, Vue } from 'vue-property-decorator';
import { IWidgetGallery } from '@/shared/model/spe/kiosk/widget-sidebar/widget-gallery/widget-gallery.model';

@Component({})
export default class GoGadgetRedirect extends Vue {
  public widgetGallery: IWidgetGallery = {};
  public filter: { [k: string]: any } = {};

  created() {
    this.widgetGallery = { ...this.$attrs };
  }

  public get widgetData() {
    return this?.widgetGallery?.data ?? null;
  }

  public get textStyle(): { [k: string]: any } {
    try {
      //@ts-ignore
      if (this.widgetData?.textStyle) {
        //@ts-ignore
        return JSON.parse(this.widgetData.textStyle);
      }
      return {};
    } catch (e) {
      console.error('Could not get property Text Style', e);
      return {};
    }
  }

  public get titleStyle(): { [k: string]: any } {
    try {
      //@ts-ignore
      if (this.widgetData?.titleStyle) {
        //@ts-ignore
        return JSON.parse(this.widgetData.titleStyle);
      }
      return {};
    } catch (e) {
      console.error('Could not get property Title Style', e);
      return {};
    }
  }

  public get iconColor(): { [k: string]: string } {
    try {
      //@ts-ignore
      if (this.widgetData?.iconColor) {
        //@ts-ignore
        return JSON.parse(this.widgetData.iconColor);
      }
      return {};
    } catch (e) {
      console.error('Could not get property Color', e);
      return {};
    }
  }

  public get cardStyle(): { [k: string]: any } {
    try {
      //@ts-ignore
      if (this.widgetData?.cardStyle) {
        //@ts-ignore
        return JSON.parse(this.widgetData.cardStyle);
      }
      return {};
    } catch (e) {
      console.error('Could not get property Card Style', e);
      return {};
    }
  }
}
