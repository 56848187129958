// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// SPE Begin AxiosSingleton
// import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';  // SPE
import { setupAxiosInterceptors, AxiosSingleton } from '@/shared/config/axios-interceptor';
// SPE End AxiosSingleton

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
// SPE Begin custom auth
// import ActivateService from './account/activate/activate.service';
// import RegisterService from './account/register/register.service';
// import UserManagementService from './admin/user-management/user-management.service';
// import LoginService from './account/login.service';
import LoginService from './account/work-center-authentication/login.service';
// import AccountService from './account/account.service';
import AccountService from './account//work-center-authentication/account.service';
// SPE End custom auth
// import AlertService from './shared/alert/alert.service'; // SPE
import { AlertService } from '@gv/ammo-grome'; // SPE

// SPE Begin remove bootstrap-sass
// import '../content/scss/global.scss';
// import '../content/scss/vendor.scss';
// SPE End remove bootstrap-sass
import TranslationService from '@/locale/translation.service';
/* tslint:disable */
// SPE Begin plugins import
import PortalVue from 'portal-vue';
// SPE End plugins import
// SPE Begin vuetify-import
import vuetify from '../plugins/vuetify';
// SPE End vuetify-import
// SPE Begin custom services
import { FARGO_LOCAL_MENU_API_BASE_URL, FARGO_QUERY_API_BASE_URL } from '@/constants';
import { LocalMenuService, QueryService } from '@gv/ammo-astra';
import ApplicationContext from '@/shared/raptor/context/application-context';
// SPE End custom services
// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
// SPE Begin regist plugins
Vue.use(PortalVue);
// SPE End regist plugins
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);
// SPE Begin store subscribe user notifications
// store.subscribe(mutation => {
//   if (!mutation.type.startsWith('user/')) return
//
//   store.dispatch('user/update', mutation).then()
// })
// SPE end store subscribe user notifications

const translationService = new TranslationService(store, i18n);
// const loginService = new LoginService();                                                                                // SPE
const loginService = new LoginService(AxiosSingleton.getInstance(), store, (<any>Vue).cookie, router); // SPE Custom auth
// const accountService = new AccountService(store, translationService, (<any>Vue).cookie, router);                        // SPE Custom auth
const accountService = new AccountService(store, translationService, (<any>Vue).cookie, router, loginService); // SPE Custom auth

// SPE Begin custom services
const localMenuService = new LocalMenuService(AxiosSingleton.getInstance(), FARGO_LOCAL_MENU_API_BASE_URL);
const queryService = new QueryService(AxiosSingleton.getInstance(), FARGO_QUERY_API_BASE_URL);
// SPE End custom services

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  // SPE Begin config vuetify
  vuetify,
  // SPE End config vuetify
  provide: {
    loginService: () => loginService,
    // SPE Begin custom auth
    // activateService: () => new ActivateService(),
    // registerService: () => new RegisterService(),
    // userManagementService: () => new UserManagementService(),
    // SPE End custom auth
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    // alertService: () => new AlertService(),   // SPE disable std service
    alertService: () => new AlertService(store), // SPE custom alert service

    // SPE Begin custom service
    localMenuService: () => localMenuService,
    queryService: () => queryService,
    // raptorWorkCenterDeviceService: () => new RaptorWorkCenterDeviceService(AxiosSingleton.getInstance()),
    // SPE End custom service
  },
  i18n,
  store,
});

ApplicationContext.setStore(store);
ApplicationContext.setI18n(i18n);

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        window.location.reload();
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    console.log('Server error!');
    return Promise.reject(error);
  }
);
