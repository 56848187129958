import { IMessage, Message } from '@/shared/model/raptor/messaging/support/message/message.model';
import { IData } from '@/shared/model/raptor/operator-landing.model';

export interface IOperatorLandingContext {
  originalMessage?: { [k: string]: any };
  data?: IData;

  getInnerMessage(): IMessage;
  getInnerHeaders(): { [k: string]: any };
  getInnerPayload(): { [k: string]: any };
}

export class OperatorLandingContext implements IOperatorLandingContext {
  constructor(public originalMessage?: { [k: string]: any }, public data?: IData) {}

  public getInnerMessage(): IMessage {
    return new Message(this.getInnerHeaders(), this.getInnerPayload());
  }

  public getInnerHeaders(): { [k: string]: any } {
    return this.originalMessage.deserializedPayload.headers;
  }

  /**
   * Return the enriched payload
   */
  public getInnerPayload(): { [k: string]: any } {
    return this.originalMessage.deserializedPayload.payload;
  }
}
