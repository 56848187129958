import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ITextFile } from '@/shared/model/text-file.model';

// SPE Begin use /services/{service}/** pattern
// const baseApiUrl = 'api/text-files';
const baseApiUrl = '/services/fargo/api/text-files';
// SPE End /services/{service}/** pattern

export default class TextFileService {
  public find(id: number): Promise<ITextFile> {
    return new Promise<ITextFile>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ITextFile): Promise<ITextFile> {
    return new Promise<ITextFile>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ITextFile): Promise<ITextFile> {
    return new Promise<ITextFile>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ITextFile): Promise<ITextFile> {
    return new Promise<ITextFile>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
