var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.widgetData
        ? _c("v-skeleton-loader", { attrs: { type: "image" } })
        : _c(
            "v-card",
            [
              _c("v-date-picker", {
                attrs: {
                  "no-title": "",
                  events: _vm.filteredEvents,
                  "event-color": _vm.eventColorByCalendarFilter,
                  "show-adjacent-months": "",
                  "show-current": _vm.model,
                  scrollable: "",
                  "full-width": "",
                  elevation: "2",
                },
                on: { "click:date": _vm.onClickDate },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }