export interface IOperatorLandingLoading {
  main: boolean;
  printLabel: boolean;
  linkDocumentToWorkOrder: boolean;
  doorInspection: boolean;
  start: boolean;
  pause: boolean;
  skip: boolean;
  complete: boolean;
}

export class OperatorLandingLoading implements IOperatorLandingLoading {
  constructor(
    public main: boolean,
    public printLabel: boolean,
    public linkDocumentToWorkOrder: boolean,
    public doorInspection: boolean,
    public start: boolean,
    public pause: boolean,
    public skip: boolean,
    public complete: boolean
  ) {}
}
