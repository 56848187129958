import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Folder = () => import('@/entities/folder/folder.vue');
const FolderUpdate = () => import('@/entities/folder/folder-update.vue');
const FolderDetails = () => import('@/entities/folder/folder-details.vue');

const TableIdNumber = () => import('@/entities/table-id-number/table-id-number.vue');
const TableIdNumberUpdate = () => import('@/entities/table-id-number/table-id-number-update.vue');
const TableIdNumberDetails = () => import('@/entities/table-id-number/table-id-number-details.vue');

const EmployeeTracker = () => import('@/entities/employee-tracker/employee-tracker.vue');
const EmployeeTrackerUpdate = () => import('@/entities/employee-tracker/employee-tracker-update.vue');
const EmployeeTrackerDetails = () => import('@/entities/employee-tracker/employee-tracker-details.vue');

const ShopFloorDevice = () => import('@/entities/shop-floor-device/shop-floor-device.vue');
const ShopFloorDeviceUpdate = () => import('@/entities/shop-floor-device/shop-floor-device-update.vue');
const ShopFloorDeviceDetails = () => import('@/entities/shop-floor-device/shop-floor-device-details.vue');

const CodeScan = () => import('@/entities/code-scan/code-scan.vue');
const CodeScanUpdate = () => import('@/entities/code-scan/code-scan-update.vue');
const CodeScanDetails = () => import('@/entities/code-scan/code-scan-details.vue');

const CustomOperationTrack = () => import('@/entities/custom-operation-track/custom-operation-track.vue');
const CustomOperationTrackUpdate = () => import('@/entities/custom-operation-track/custom-operation-track-update.vue');
const CustomOperationTrackDetails = () => import('@/entities/custom-operation-track/custom-operation-track-details.vue');

const LinkDocumentToTag = () => import('@/entities/link-document-to-tag/link-document-to-tag.vue');
const LinkDocumentToTagUpdate = () => import('@/entities/link-document-to-tag/link-document-to-tag-update.vue');
const LinkDocumentToTagDetails = () => import('@/entities/link-document-to-tag/link-document-to-tag-details.vue');

const SkippedOperationTrack = () => import('@/entities/skipped-operation-track/skipped-operation-track.vue');
const SkippedOperationTrackUpdate = () => import('@/entities/skipped-operation-track/skipped-operation-track-update.vue');
const SkippedOperationTrackDetails = () => import('@/entities/skipped-operation-track/skipped-operation-track-details.vue');

const ShopFloorDeviceIssue = () => import('@/entities/shop-floor-device-issue/shop-floor-device-issue.vue');
const ShopFloorDeviceIssueUpdate = () => import('@/entities/shop-floor-device-issue/shop-floor-device-issue-update.vue');
const ShopFloorDeviceIssueDetails = () => import('@/entities/shop-floor-device-issue/shop-floor-device-issue-details.vue');

const TextFile = () => import('@/entities/text-file/text-file.vue');
const TextFileUpdate = () => import('@/entities/text-file/text-file-update.vue');
const TextFileDetails = () => import('@/entities/text-file/text-file-details.vue');

const ShopFloorConveyor = () => import('@/entities/shop-floor-conveyor/shop-floor-conveyor.vue');
const ShopFloorConveyorUpdate = () => import('@/entities/shop-floor-conveyor/shop-floor-conveyor-update.vue');
const ShopFloorConveyorDetails = () => import('@/entities/shop-floor-conveyor/shop-floor-conveyor-details.vue');

const ShopFloorConveyorLine = () => import('@/entities/shop-floor-conveyor-line/shop-floor-conveyor-line.vue');
const ShopFloorConveyorLineUpdate = () => import('@/entities/shop-floor-conveyor-line/shop-floor-conveyor-line-update.vue');
const ShopFloorConveyorLineDetails = () => import('@/entities/shop-floor-conveyor-line/shop-floor-conveyor-line-details.vue');

const TextToTranslate = () => import('@/entities/text-to-translate/text-to-translate.vue');
const TextToTranslateUpdate = () => import('@/entities/text-to-translate/text-to-translate-update.vue');
const TextToTranslateDetails = () => import('@/entities/text-to-translate/text-to-translate-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

// SPE Begin
const SpeEmployeesTracker = () => import('@/entities/employee-tracker/spe/spe-employees-tracker.vue');
const SpeManufacturingPlan = () => import('@/entities/spe/manufacturing-plan/manufacturing-plan.vue');
const RaptorOperatorLanding = () => import('@/raptor/operator-landing/raptor-operator-landing.vue');
// SPE End

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'folder',
      name: 'Folder',
      component: Folder,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'folder/new',
      name: 'FolderCreate',
      component: FolderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'folder/:folderId/edit',
      name: 'FolderEdit',
      component: FolderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'folder/:folderId/view',
      name: 'FolderView',
      component: FolderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'table-id-number',
      name: 'TableIdNumber',
      component: TableIdNumber,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'table-id-number/new',
      name: 'TableIdNumberCreate',
      component: TableIdNumberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'table-id-number/:tableIdNumberId/edit',
      name: 'TableIdNumberEdit',
      component: TableIdNumberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'table-id-number/:tableIdNumberId/view',
      name: 'TableIdNumberView',
      component: TableIdNumberDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee-tracker',
      name: 'EmployeeTracker',
      component: EmployeeTracker,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee-tracker/new',
      name: 'EmployeeTrackerCreate',
      component: EmployeeTrackerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee-tracker/:employeeTrackerId/edit',
      name: 'EmployeeTrackerEdit',
      component: EmployeeTrackerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee-tracker/:employeeTrackerId/view',
      name: 'EmployeeTrackerView',
      component: EmployeeTrackerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-device',
      name: 'ShopFloorDevice',
      component: ShopFloorDevice,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-device/new',
      name: 'ShopFloorDeviceCreate',
      component: ShopFloorDeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-device/:shopFloorDeviceId/edit',
      name: 'ShopFloorDeviceEdit',
      component: ShopFloorDeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-device/:shopFloorDeviceId/view',
      name: 'ShopFloorDeviceView',
      component: ShopFloorDeviceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'code-scan',
      name: 'CodeScan',
      component: CodeScan,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'code-scan/new',
      name: 'CodeScanCreate',
      component: CodeScanUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'code-scan/:codeScanId/edit',
      name: 'CodeScanEdit',
      component: CodeScanUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'code-scan/:codeScanId/view',
      name: 'CodeScanView',
      component: CodeScanDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-operation-track',
      name: 'CustomOperationTrack',
      component: CustomOperationTrack,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-operation-track/new',
      name: 'CustomOperationTrackCreate',
      component: CustomOperationTrackUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-operation-track/:customOperationTrackId/edit',
      name: 'CustomOperationTrackEdit',
      component: CustomOperationTrackUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-operation-track/:customOperationTrackId/view',
      name: 'CustomOperationTrackView',
      component: CustomOperationTrackDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'link-document-to-tag',
      name: 'LinkDocumentToTag',
      component: LinkDocumentToTag,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'link-document-to-tag/new',
      name: 'LinkDocumentToTagCreate',
      component: LinkDocumentToTagUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'link-document-to-tag/:linkDocumentToTagId/edit',
      name: 'LinkDocumentToTagEdit',
      component: LinkDocumentToTagUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'link-document-to-tag/:linkDocumentToTagId/view',
      name: 'LinkDocumentToTagView',
      component: LinkDocumentToTagDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'skipped-operation-track',
      name: 'SkippedOperationTrack',
      component: SkippedOperationTrack,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'skipped-operation-track/new',
      name: 'SkippedOperationTrackCreate',
      component: SkippedOperationTrackUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'skipped-operation-track/:skippedOperationTrackId/edit',
      name: 'SkippedOperationTrackEdit',
      component: SkippedOperationTrackUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'skipped-operation-track/:skippedOperationTrackId/view',
      name: 'SkippedOperationTrackView',
      component: SkippedOperationTrackDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-device-issue',
      name: 'ShopFloorDeviceIssue',
      component: ShopFloorDeviceIssue,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-device-issue/new',
      name: 'ShopFloorDeviceIssueCreate',
      component: ShopFloorDeviceIssueUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-device-issue/:shopFloorDeviceIssueId/edit',
      name: 'ShopFloorDeviceIssueEdit',
      component: ShopFloorDeviceIssueUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-device-issue/:shopFloorDeviceIssueId/view',
      name: 'ShopFloorDeviceIssueView',
      component: ShopFloorDeviceIssueDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-file',
      name: 'TextFile',
      component: TextFile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-file/new',
      name: 'TextFileCreate',
      component: TextFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-file/:textFileId/edit',
      name: 'TextFileEdit',
      component: TextFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-file/:textFileId/view',
      name: 'TextFileView',
      component: TextFileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-conveyor',
      name: 'ShopFloorConveyor',
      component: ShopFloorConveyor,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-conveyor/new',
      name: 'ShopFloorConveyorCreate',
      component: ShopFloorConveyorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-conveyor/:shopFloorConveyorId/edit',
      name: 'ShopFloorConveyorEdit',
      component: ShopFloorConveyorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-conveyor/:shopFloorConveyorId/view',
      name: 'ShopFloorConveyorView',
      component: ShopFloorConveyorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-conveyor-line',
      name: 'ShopFloorConveyorLine',
      component: ShopFloorConveyorLine,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-conveyor-line/new',
      name: 'ShopFloorConveyorLineCreate',
      component: ShopFloorConveyorLineUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-conveyor-line/:shopFloorConveyorLineId/edit',
      name: 'ShopFloorConveyorLineEdit',
      component: ShopFloorConveyorLineUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'shop-floor-conveyor-line/:shopFloorConveyorLineId/view',
      name: 'ShopFloorConveyorLineView',
      component: ShopFloorConveyorLineDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-to-translate',
      name: 'TextToTranslate',
      component: TextToTranslate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-to-translate/new',
      name: 'TextToTranslateCreate',
      component: TextToTranslateUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-to-translate/:textToTranslateId/edit',
      name: 'TextToTranslateEdit',
      component: TextToTranslateUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-to-translate/:textToTranslateId/view',
      name: 'TextToTranslateView',
      component: TextToTranslateDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here

    // SPE Begin
    {
      path: '/shop-floor/manufacturing-plans',
      name: 'SpeManufacturingPlan',
      component: SpeManufacturingPlan,
      meta: { authorities: [Authority.SHOP_FOOR] },
    },
    {
      path: '/shop-floor/manufacturing-plans/:id/operator-landing',
      component: RaptorOperatorLanding,
      meta: { authorities: [Authority.SHOP_FOOR] },
    },
    // {
    //   path: '/shop-floor/manufacturing-plans/operator-landing/operators',
    //   name: 'ErpIdNumberOperators',
    //   component: ErpIdNumberOperators,
    //   meta: { authorities: [Authority.USER] },
    // },
    {
      path: '/shop-floor/operators',
      name: 'SpeEmployeesTracker',
      component: SpeEmployeesTracker,
      meta: { authorities: [Authority.USER] },
    },
    // SPE End
  ],
};
