import axios from 'axios';

import { IShopFloorConveyor } from '@/shared/model/shop-floor-conveyor.model';

const baseApiUrl = '/services/fargo/api/v1/shop-floor-conveyors';

export default class ShopFloorConveyorExtendedService {
  public retrieve(criteria?: { [k: string]: any }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, {
          params: {
            ...criteria,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
