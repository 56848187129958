/**
 *
 * @param timeInUTC time in UTC (Coordinated Universal Time)
 * @return a number with milliseconds since epoch
 *
 * Example:
 *  toNumberOfMillisecondsSinceEpoch("2024-09-23T12:00:00Z") return 1727092800000
 */
export function toNumberOfMillisecondsSinceEpoch(timeInUTC: string): number {
  const date = new Date(timeInUTC);
  return date.getTime();
}

/**
 * Formats a date string into a localized string in Portuguese.
 *
 * @param dateString - A date string to be formatted..
 * @return A string formatted as "Segunda-Feira, 25 de Setembro às 12:00".
 *
 * Example:
 *  formatDate("2024-09-25T12:00:00Z") returns "Segunda-Feira, 25 de Setembro às 12:00".
 */
export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('pt-PT', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });

  const formattedTime = date.toLocaleTimeString('pt-PT', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${formattedDate} às ${formattedTime}`;
}

/**
 * Formats a time string into a localized time string in Portuguese.
 *
 * @param timeString - A time string to be formatted.
 * @return A string formatted as "12:00".
 *
 * Example:
 *  formatTime("2024-09-25T12:00:00Z") returns "12:00".
 */
export function formatTime(timeString: string): string {
  const date = new Date(timeString);
  const formattedTime = date.toLocaleTimeString('pt-PT', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${formattedTime}`;
}
