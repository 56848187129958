import { ICustomOperationTrack } from '@/shared/model/custom-operation-track.model';

export interface ICompletePayload {
  customOperationTrack: ICustomOperationTrack;
  data: IData;
}

export interface IData {
  indexes: { child: number; parent: number };
  rowData: { [k: string]: any };
}

export class CompletePayload implements ICompletePayload {
  constructor(public customOperationTrack: ICustomOperationTrack, public data: IData) {}
}
