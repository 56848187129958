import dayjs from 'dayjs';

export default class TimeUtils {
  // Get the current date in 'D' format (day of the month)
  static getCurrentDay(): string {
    return dayjs().format('D');
  }

  // Get the current day of the week
  static getCurrentDayOfWeek(translateFunction): string {
    const dayOfWeek = dayjs().locale('en').format('dddd');
    return translateFunction(`spe.wibaultApp.goComponent.days.${dayOfWeek.toLowerCase()}`);
  }
}
