import axios, { AxiosResponse } from 'axios';

import { IOperationIdentifier } from '@/shared/model/raptor/operation-identifier.model';
import { ICustomOperationTrack } from '@/shared/model/custom-operation-track.model';

const baseApiUrl = 'services/fargo/api/v1/custom-operation-tracks';

export default class CustomOperationTrackExtendedService {
  /**
   * Create an operation track using the minimal data required
   */
  public createOne(entity: ICustomOperationTrack): Promise<AxiosResponse<ICustomOperationTrack>> {
    return new Promise<AxiosResponse<ICustomOperationTrack>>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createBulk(entities: ICustomOperationTrack[]): Promise<ICustomOperationTrack[]> {
    return new Promise<ICustomOperationTrack[]>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/bulk`, entities)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  deleteAllByOperationIdentifier(operationIdentifiers: IOperationIdentifier[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/bulk`, { data: operationIdentifiers })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
