import axios from 'axios';

const baseApiUrl = 'services/panther/api/v1/widget-notification-centers';

export default class WidgetNotificationCenterExtendedService {
  public retrieveOrder(criteria?: { [k: string]: any }, clientIpAddress?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, {
          params: {
            ...criteria,
          },
          headers: {
            'X-Client-IP-Address': clientIpAddress,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
