import { Component, Provide, Vue } from 'vue-property-decorator';

import buildCriteria, { nullIfEmpty } from '@/shared/spe/filters';
import { IWidgetGallery } from '@/shared/model/spe/kiosk/widget-sidebar/widget-gallery/widget-gallery.model';
import CalendarEventExtendedService from '@/entities/spe/kiosk/widget-sidebar/calendar-event/calendar-event-extended.service';

import SocietyExtendedService from '@/entities/spe/kiosk/widget-sidebar/society/society-extended.service';

@Component({})
export default class GoGadgetCalendar extends Vue {
  @Provide('calendarEventExtendedService')
  private calendarEventExtendedService = () => new CalendarEventExtendedService();

  @Provide('societyExtendedService')
  private societyExtendedService = () => new SocietyExtendedService();

  public events = [];
  public filteredEvents: string[] = [];
  public eventsByCalendarId = [];

  public isFetching = false;

  public widgetGallery: IWidgetGallery = {};
  public filter: { [k: string]: any } = {};

  public model = new Date().toISOString().substr(0, 10);

  created() {
    this.widgetGallery = { ...this.$attrs };
  }

  async mounted() {
    await this.retrieveFilter();
    await this.retrieveCalendarEventsByFilter();
  }

  public get widgetData() {
    return this?.widgetGallery?.data ?? null;
  }

  onClickDate(date: string): void {
    //@ts-ignore
    const url = `${this.widgetGallery.data.onClickUrl}?${buildCriteria(this.filter)}&start.in=${date}`;
    this.$router.push({ path: url, query: { 'start.in': date } });
  }

  public async retrieveFilter(): Promise<void> {
    if (this.widgetGallery.interCompany) {
      try {
        this.isFetching = true;
        const res = await this.societyExtendedService()
          //@ts-ignore
          .findByCodeInSocietyGroup(this.widgetGallery.societyId);
        this.filter['calendar.society_id.in'] = nullIfEmpty(res.map(it => it.id));
      } catch (error) {
        console.error('Error fetching societies by group ID:', error);
      } finally {
        this.isFetching = false;
      }
    }
  }

  public async retrieveCalendarEventsByFilter(): Promise<void> {
    //
    if (!this.filter['calendar.society_id.in']) {
      console.warn('Filter not set.');
      return;
    }

    try {
      this.isFetching = true;
      const res = await this.calendarEventExtendedService().retrieve();

      const calendarIds = this.filter['calendar.society_id.in'].split(',').map((id: string) => parseInt(id, 10));
      this.events = res.data;

      // Filter by calendar IDs
      this.eventsByCalendarId = this.events.filter(event => calendarIds.includes(event.calendar.id));

      // Expand each event into individual dates for filteredEvents
      this.filteredEvents = [];
      this.eventsByCalendarId.forEach(event => {
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);

        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
          const formattedDate = date.toISOString().substr(0, 10);
          if (!this.filteredEvents.includes(formattedDate)) {
            this.filteredEvents.push(formattedDate);
          }
        }
      });
    } catch (error) {
      console.error('Error retrieving calendar events:', error);
    } finally {
      this.isFetching = false;
    }
  }

  public eventColor() {
    return (date: string): string | null => {
      // Helper function to format date as 'YYYY-MM-DD'
      const formatDate = (inputDate: string | Date): string => new Date(inputDate).toISOString().substr(0, 10);

      // Filter events that overlap with the provided date
      const eventsOnDate = this.eventsByCalendarId.filter(event => {
        const startDate = formatDate(event.start);
        const endDate = formatDate(event.end);
        return date >= startDate && date <= endDate; // Date falls within event range
      });

      // Return the color of the first matching event, or null if no match
      return eventsOnDate.length > 0 ? this.getEventColor(eventsOnDate[0]) : null;
    };
  }

  public get eventColorByCalendarFilter() {
    return this.eventColor();
  }

  public getEventColor(event) {
    switch (event.calendarId) {
      case 1:
        return '#BDBDBD'; //grey-lighten-1
      case 2:
        return '#757575'; //grey-darken-2
      case 3:
        return '#000000'; // black
    }
  }
}
