/**
 * Formery "@/api/backend.js"
 */
import { Query } from '@gv/ammo-astra/dist/services/query/query';
import { QueryService } from '@gv/ammo-astra';
import { AxiosStatic } from 'axios';

export default class RaptorOperatorLandingService {
  constructor(private readonly axios: AxiosStatic, private readonly queryService: QueryService) {}

  /**
   * Raptor backend
   */

  /**
   * Gets work center parameterization
   * @returns {AxiosPromise<any>}
   */
  getProductionResource(workCenter: string, manufacturingPlan: string): Promise<any> {
    const params = { workCenter, manufacturingPlan };

    return this.queryService.query(Query.ERP_SAGE_X3_PRODUCTION_RESOURCE, params);
  }

  /**
   * Gets work center load
   */
  getProductionResourceLoad(manufacturingSite, workCenter, manufacturingPlan): Promise<any> {
    const params = { manufacturingSite, workCenter, manufacturingPlan };

    return this.queryService.query(Query.ERP_SAGE_X3_PRODUCTION_RESOURCE_LOAD, params);
  }
}
