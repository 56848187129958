import { Component, Vue } from 'vue-property-decorator';

import WidgetSidebar from '@/core/spe/kiosk/widget-sidebar/widget-sidebar.vue';

@Component({
  components: {
    WidgetSidebar,
  },
})
export default class kiosk extends Vue {
  public items = [
    {
      img: './content/images/kiosk/human-resources.png',
      title: this.$t('speKiosk.humanResources.title'),
      url: 'https://marut.roots.grupo-valco.com',
      color: 'white',
    },
    {
      img: './content/images/kiosk/help-desk.png',
      title: this.$t('speKiosk.helpDesk.title'),
      url: 'https://sentry.roots.grupo-valco.com/otrs/customer.pl',
      color: 'white',
    },
    {
      img: './content/images/kiosk/stock-material.jpg',
      title: this.$t('speKiosk.stock.title'),
      url: 'https://aurora.roots.grupo-valco.com/stockcontrol/material-request/create/shop-floor',
      color: 'white',
    },
    {
      img: './content/images/kiosk/data-sheet.jpg',
      title: this.$t('speKiosk.quality.title'),
      url: 'http://martin-001.internal.grupo-valco.com:50102/spe/go-file-browser',
      color: 'white',
    },
    {
      img: './content/images/kiosk/miscellaneous-stock.jpg',
      title: this.$t('speKiosk.miscellaneousIssues.title'),
      url: 'https://wibault.roots.grupo-valco.com/stock/miscellaneous-issues',
      color: 'white',
    },
  ];
}
