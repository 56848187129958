var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.widgetData
        ? _c("v-skeleton-loader", { attrs: { type: "image" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        {
          style: _vm.cardStyle,
          attrs: {
            href: _vm.widgetData.onClickUrl,
            outlined: "",
            rounded: "",
            target: "_blank",
            elevation: "2",
          },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "title-wrapper" },
            [
              _c("v-icon", { style: _vm.iconColor, attrs: { left: "" } }, [
                _vm._v(_vm._s(_vm.widgetData.icon)),
              ]),
              _vm._v(" "),
              _c("span", { style: _vm.titleStyle }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.widgetData.title) + "\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-subtitle", {
            style: _vm.textStyle,
            domProps: { innerHTML: _vm._s(_vm.widgetData.content) },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }