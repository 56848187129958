import { Module } from 'vuex';
import { ErpLanguage, LocalMenuService, QueryService } from '@gv/ammo-astra';
import { AxiosSingleton } from '@/shared/config/axios-interceptor';
import { FARGO_LOCAL_MENU_API_BASE_URL, FARGO_QUERY_API_BASE_URL } from '@/constants';
import { Query } from '@gv/ammo-astra/dist/services/query/query';
import * as type from '@/shared/config/store/raptor/mutation-types/work-center-store-types';
import { IRejectionReasonCategory, RejectionReasonCategory } from '@/shared/model/raptor/rejection-reason-category.model';
import { IRejectionReason } from '@/shared/model/raptor/rejection-reason.model';
import { appLogLevel, LogLevel } from '@/shared/config/logger-spe';

const logLevel = appLogLevel();
const localMenuService = new LocalMenuService(AxiosSingleton.getInstance(), FARGO_LOCAL_MENU_API_BASE_URL);
const queryService = new QueryService(AxiosSingleton.getInstance(), FARGO_QUERY_API_BASE_URL);

export interface WorkCenterAccountStateStorable {
  status: string;
  token: string;
  user: { [k: string]: any };
  rejectionReasonCategories: IRejectionReasonCategory[];
  rejectionReasons: IRejectionReason[];
}

export const defaultWorkCenterAccountState: WorkCenterAccountStateStorable = {
  status: '',
  token: localStorage.getItem('token') || '',
  user: {},
  rejectionReasonCategories: [],
  rejectionReasons: [],
};

export const workCenterStore: Module<WorkCenterAccountStateStorable, any> = {
  state: { ...defaultWorkCenterAccountState },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    rejectionReasonCategories: state => state.rejectionReasonCategories,
    rejectionReasons: state => state.rejectionReasons,
  },
  mutations: {
    authRequest(state) {
      state.status = 'loading';
    },
    authSuccess(state, payload) {
      state.status = 'success';
      state.token = payload.token;
      state.user = payload.user;
    },
    authError(state) {
      state.status = 'error';
    },
    logout(state) {
      (state.status = ''), (state.token = ''), (state.user = {});
    },
    rejectionReasons(state, payload: Array<{ [k: string]: any }>) {
      state.rejectionReasons = payload;
    },
    rejectionReasonCategories(state, payload: IRejectionReasonCategory[]) {
      state.rejectionReasonCategories = payload;
    },
  },
  actions: {
    retrieveCorrelatedData({ dispatch, commit, state }) {
      return new Promise((resolve, reject) => {
        Promise.all([dispatch('retrieveRejectionReasonCategories'), dispatch('retrieveAllRejectionReasons')])
          .then(() => {
            // Resolve the promise once all the actions complete successfully
            resolve('All data fetched successfully!');
          })
          .catch(error => {
            // Reject the promise if any of the actions fail
            reject(error);
          });
      });
    },
    retrieveRejectionReasonCategories({ commit, state }) {
      return new Promise((resolve, reject) => {
        const params = {
          language: 'POR',
        };
        localMenuService.localMenu(6070, ErpLanguage.POR).then(
          res => {
            logLevel === LogLevel.DEBUG &&
              console.debug(`Finished retrieving rejection reason categories. Found ${res.data.length} rejection reason categories.`);
            commit(
              type.rejectionReasonCategories,
              res.data.map((item: any) => new RejectionReasonCategory(item.LANNUM_0, item.CODE_0, item.LANMES_0))
            );
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    retrieveAllRejectionReasons({ commit, state }) {
      return new Promise((resolve, reject) => {
        const params = {
          language: 'POR',
          workCenter: state.user.data.username,
          manufacturingSite: state.user.data.site,
        };
        queryService.query(Query.ERP_SAGE_X3_MANUFACTURING_WORK_CENTER_REJECTION_REASONS, params).then(
          res => {
            logLevel === LogLevel.DEBUG &&
              console.debug(`Finished retrieving rejection reasons. Found ${res.data.length} rejection reasons.`);
            commit(type.rejectionReasons, res.data);
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  namespaced: true,
};
